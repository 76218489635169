const Auth = {
    isAutenthicated() {
        return sessionStorage.getItem('isAuthenticated');
    },
    getToken() {
        return sessionStorage.getItem('Auth_Token')
    },
    // setEmpresaSelecionada(idEmpresa) {
    //     return sessionStorage.setItem('EMPRESA_SELECIONADA', idEmpresa)
    // },
    // getEmpresaSelecionada(){
    //     return sessionStorage.getItem('EMPRESA_SELECIONADA')
    // },
    // getUserId() {
    //     return sessionStorage.getItem('USER_ID')
    // },
    // getUserEmpresas(){
    //     return sessionStorage.getItem('USER_EMPRESAS')
    // }
}
export default Auth;